<template>
  <div>
    <general-table
      ref="countriesTable"
      :api-url="apiUrl"
      :block-content="false"
      :add-type="addType"
      :columns="columns"
      :type="type"
      :selectable="false"
    />
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'late-reasons',
      type: 'page',
      filter: {},
      types: '',
      columns: [
        { key: 'id', sortable: true },
        { key: 'user.name', label: 'Engineer' },
        { key: 'reason', label: 'Reason' },
        { key: 'status', label: 'Status' },
        { key: 'created_at', label: 'created At' },
        { key: 'request.request_code', label: 'Request Code' },
        { key: 'request.source_date', label: 'Source Date' },
        { key: 'request.certificate_name.en', label: 'Certificate Name En' },
        { key: 'request.certificate_name.ar', label: 'Certificate Name Ar' },
        { key: 'request.certificate.name.en', label: 'Sub Certificate Name En' },
        { key: 'request.certificate.name.ar', label: 'Sub Certificate Name Ar' },
      ],
    }
  },
  computed: {
    apiUrl() {
      let base = 'late-reasons?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }
      // console.log(base.slice(0, -1))
      return base.slice(0, -1)
    },
  },
  mounted() {},

  methods: {
    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },
  },
}
</script>

  <style></style>
